import React from "react";
import "./BottomButton.css";

export default function BottomButton({ theme }) {
  function GoDownEvent() {
    const targetElement = document.getElementById("educations");
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  const onMouseEnter = (color, bgColor) => {
    const bottomButton = document.getElementById("bottomButton");
    bottomButton.style.color = color;
    bottomButton.style.backgroundColor = bgColor;
  };

  const onMouseLeave = (color, bgColor) => {
    const bottomButton = document.getElementById("bottomButton");
    bottomButton.style.color = color;
    bottomButton.style.backgroundColor = bgColor;
  };

  return (
    <div
      onClick={GoDownEvent}
      id="bottomButton"
      style={{
        color: theme.body,
        backgroundColor: theme.text,
        border: `solid 1px ${theme.text}`,
      }}
      title="Scroll to Diplômes"
      onMouseEnter={() => onMouseEnter(theme.text, theme.body)}
      onMouseLeave={() => onMouseLeave(theme.body, theme.text)}
    >
      <i className="fas fa-arrow-down" aria-hidden="true" />
    </div>
  );
}
