import React, { Component } from "react";

export default class NegotiationImg extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width="844"
        height="424.59157"
        viewBox="0 0 844 624.59157"
      >
        <circle cx="83" cy="83" r="83" fill="#f0f0f0" />
        <circle cx="761" cy="269" r="83" fill="#f0f0f0" />
        <path
          d="M679.716,755.57537l-14.5923-6.1443-10.01026-73.15138H521.80882l-10.85038,72.85252-13.05511,6.52746a3.10016,3.10016,0,0,0,1.38657,5.873H678.51333A3.1,3.1,0,0,0,679.716,755.57537Z"
          transform="translate(-178 -137.70421)"
          fill="#e6e6e6"
        />
        <path
          d="M916.60741,686.01216H261.77821a12.97344,12.97344,0,0,1-12.9443-12.97332V580.69932h680.7178v92.33952A12.97357,12.97357,0,0,1,916.60741,686.01216Z"
          transform="translate(-178 -137.70421)"
          fill="#ccc"
        />
        <path
          d="M930.23622,624.34119h-682V229.23991a15.6572,15.6572,0,0,1,15.63964-15.63908H914.59639a15.65735,15.65735,0,0,1,15.63983,15.63908Z"
          transform="translate(-178 -137.70421)"
          fill="#3f3d56"
        />
        <path
          d="M889.50306,595.66469H288.96938a12.07023,12.07023,0,0,1-12.057-12.05666V254.334a12.07088,12.07088,0,0,1,12.057-12.05741H889.50306a12.07088,12.07088,0,0,1,12.057,12.05741V583.608A12.07023,12.07023,0,0,1,889.50306,595.66469Z"
          transform="translate(-178 -137.70421)"
          fill="#fff"
        />
        <path
          d="M757.65622,762.29577l-337.44821,0a1.56682,1.56682,0,0,1-1.53909-1.13364,1.52911,1.52911,0,0,1,1.47725-1.91892l337.385,0a1.61535,1.61535,0,0,1,1.61617,1.19368A1.52819,1.52819,0,0,1,757.65622,762.29577Z"
          transform="translate(-178 -137.70421)"
          fill="#ccc"
        />
        <path
          d="M821.43332,537.04776H694.90707a1.208,1.208,0,0,1-1.30291-1.07809V433.9107a1.208,1.208,0,0,1,1.30291-1.07809H821.43332a1.20794,1.20794,0,0,1,1.3029,1.07809v102.059A1.20794,1.20794,0,0,1,821.43332,537.04776Z"
          transform="translate(-178 -137.70421)"
          fill="#f0f0f0"
        />
        <path
          d="M807.68046,444.12443H708.65992a1.30485,1.30485,0,0,0-1.3029,1.3029v76.72644a1.30487,1.30487,0,0,0,1.3029,1.30291h99.02054a1.30488,1.30488,0,0,0,1.30291-1.30291V445.42733A1.30486,1.30486,0,0,0,807.68046,444.12443Z"
          transform="translate(-178 -137.70421)"
          fill="#fff"
        />
        <circle cx="577.72391" cy="345.82139" r="12.59002" fill="#ffb8b8" />
        <polygon
          points="596.144 385.752 568.23 385.752 569.142 365.23 569.359 365.002 572.492 361.692 582.46 361.692 586.155 365.271 586.453 365.56 594.32 373.1 596.144 385.752"
          fill="#8b5599"
        />
        <path
          d="M751.03024,523.45668H729.91167c-.052-4.63254-.081-7.40628-.081-7.40628l1.22175-.74411.0058-.00289h.003l13.45752-8.17064,2.82579-4.58912.01456.16214.831,9.67333S749.56236,516.81477,751.03024,523.45668Z"
          transform="translate(-178 -137.70421)"
          fill="#2f2e41"
        />
        <path
          d="M786.52421,523.45668H764.15483c.33873-13.50966-1.48527-20.913-.54711-20.913l.54711.43142,6.59269,5.20581,8.689,3.18777h.0058l5.99044,2.19755,1.051.38509v.49511C786.48661,515.63347,786.4924,518.87915,786.52421,523.45668Z"
          transform="translate(-178 -137.70421)"
          fill="#2f2e41"
        />
        <path
          d="M743.813,487.4646s1.06969-1.87855.821-8.15415,9.30754-2.72619,17.29277-3.32485,6.2949,10.47726,6.2949,10.47726.57608-.6584,1.80948-6.004-1.56707-10.67263-1.56707-10.67263c-.82478-5.61719-5.26976-3.81688-5.26976-3.81688a2.33112,2.33112,0,0,1,1.72951,2.61519c-1.812-3.17689-6.257-1.37658-6.257-1.37658-5.92832-3.72017-11.93661,1.17882-11.93661,1.17882-7.16171,1.62539-2.96164,7.32079-2.96164,7.32079C736.56633,479.34734,743.813,487.4646,743.813,487.4646Z"
          transform="translate(-178 -137.70421)"
          fill="#2f2e41"
        />
        <path
          d="M732.7318,523.45668h-8.67739a26.16263,26.16263,0,0,0,.00579-3.21094c-.51827-2.06438,6.77224-4.85547,6.99221-4.93945l.0058-.00289h.003l1.38971.747Z"
          transform="translate(-178 -137.70421)"
          fill="#2f2e41"
        />
        <path
          d="M788.09064,523.45668h-9.11459l-1.13778-11.23391,1.27675-.93231s.11593.02607.32148.07818h.0058a25.00318,25.00318,0,0,1,5.99044,2.19755,3.71136,3.71136,0,0,1,1.051.8802,1.08505,1.08505,0,0,1,.249.77015C786.68637,515.63637,787.21914,518.72859,788.09064,523.45668Z"
          transform="translate(-178 -137.70421)"
          fill="#2f2e41"
        />
        <path
          d="M821.02115,406.82952H694.49489a1.20809,1.20809,0,0,1-1.3029-1.07847V303.65575a1.2081,1.2081,0,0,1,1.3029-1.07848H821.02115a1.20807,1.20807,0,0,1,1.3029,1.07848v102.0953A1.20807,1.20807,0,0,1,821.02115,406.82952Z"
          transform="translate(-178 -137.70421)"
          fill="#f0f0f0"
        />
        <path
          d="M807.26839,313.86934H708.24784a1.30487,1.30487,0,0,0-1.3029,1.3029v76.72645a1.30487,1.30487,0,0,0,1.3029,1.3029h99.02055a1.30487,1.30487,0,0,0,1.3029-1.3029V315.17224A1.30487,1.30487,0,0,0,807.26839,313.86934Z"
          transform="translate(-178 -137.70421)"
          fill="#fff"
        />
        <path
          d="M782.13106,393.20159c.5588-2.01226.9931-3.74656,1.216-4.97419a3.54739,3.54739,0,0,0,.02027-1.14655c-.83386-5.93255-15.8954-10.94727-17.82659-11.56688l-.46615-5.16528L746.116,368.30748l-2.40024,6.60716-6.804,2.55079a3.24125,3.24125,0,0,0-.69778.359,3.36855,3.36855,0,0,0-1.38686,3.59312l2.89823,11.784h44.40579Z"
          transform="translate(-178 -137.70421)"
          fill="#8b5599"
        />
        <path
          d="M742.82974,393.20159H728.11851c.527-3.28332.999-6.18733,1.30587-8.03456,1.12336-6.723,6.02225-7.29626,6.78948-7.34257.06086-.0058.09847-.0058.10137-.0058h3.0951Z"
          transform="translate(-178 -137.70421)"
          fill="#8b5599"
        />
        <path
          d="M782.13106,393.20159h3.56706a11.608,11.608,0,0,0-2.33075-6.12074,7.31213,7.31213,0,0,0-2.55079-2.12228l-.26927-.11582-3.13565,8.35884Z"
          transform="translate(-178 -137.70421)"
          fill="#8b5599"
        />
        <circle
          id="e3e8db5d-807d-4643-8c1f-aca9b0b4bfae"
          data-name="Ellipse 109"
          cx="576.85456"
          cy="213.68335"
          r="13.65055"
          fill="#a0616a"
        />
        <path
          id="b5b1c5aa-1c33-4801-a876-6607b8c48426-283"
          data-name="Path 696"
          d="M745.69221,344.73711c1.41317,1.67235,3.80422,2.144,6.01022,2.33582a56.60977,56.60977,0,0,0,15.37277-1.255c.31444,3.02846-.539,6.2941,1.24427,8.78577a40.78807,40.78807,0,0,0,1.44911-9.24621,8.85594,8.85594,0,0,0-.51209-3.96009,3.64179,3.64179,0,0,0-3.10257-2.2873,7.776,7.776,0,0,1,3.46866-1.48234l-4.33426-2.17814,1.11311-1.14275-7.847-.48019,2.27292-1.4437a49.2841,49.2841,0,0,0-10.3288-.32475,8.85966,8.85966,0,0,0-4.56651,1.26942c-1.29549.92668-2.04429,2.76792-1.23978,4.12809a5.994,5.994,0,0,0-4.56785,3.62454,9.67644,9.67644,0,0,0-.34677,4.65141,32.96839,32.96839,0,0,0,1.72851,7.31332"
          transform="translate(-178 -137.70421)"
          fill="#2f2e41"
        />
        <path
          d="M355.73622,534.19329V303.33219a2.75936,2.75936,0,0,1,2.98909-2.43867H648.99869a2.75942,2.75942,0,0,1,2.98909,2.43867v230.8611a2.75942,2.75942,0,0,1-2.98909,2.43867H358.72531A2.75936,2.75936,0,0,1,355.73622,534.19329Z"
          transform="translate(-178 -137.70421)"
          fill="#e4e4e4"
        />
        <path
          d="M387.28745,329.78858V505.81249a2.99361,2.99361,0,0,0,2.98909,2.98909H617.447a2.9936,2.9936,0,0,0,2.98908-2.98909V329.78858a2.9936,2.9936,0,0,0-2.98908-2.98908H390.27654A2.9936,2.9936,0,0,0,387.28745,329.78858Z"
          transform="translate(-178 -137.70421)"
          fill="#fff"
        />
        <path
          d="M481.51013,508.80158h45.71961l-2.84946-42.76385-16.49316,3.80609L491.84576,463.673l-.42521-.15941-.02659-.0133a.21456.21456,0,0,0-.0133.03985C491.12833,464.55648,486.09333,484.71618,481.51013,508.80158Z"
          transform="translate(-178 -137.70421)"
          fill="#8b5599"
        />
        <polygon
          points="275.957 335.315 280.228 371.097 318.761 371.097 313.846 325.969 313.799 325.53 313.421 325.809 313.381 325.836 303.875 332.771 275.957 335.315"
          fill="#2f2e41"
        />
        <path
          d="M520.46781,508.80158H554.9751l.49818-28.17049-25.39392-8.42258-5.69908-6.17078S522.58005,484.60328,520.46781,508.80158Z"
          transform="translate(-178 -137.70421)"
          fill="#2f2e41"
        />
        <path
          d="M437.34458,508.80158h28.59577l-1.83347-25.63309-10.14946-10.14958S445.92668,489.86405,437.34458,508.80158Z"
          transform="translate(-178 -137.70421)"
          fill="#2f2e41"
        />
        <path
          d="M546.83815,508.80158h15.085c-.06649-8.21-.10638-13.88266-.10638-15.48346a14.952,14.952,0,0,0-6.34354-12.687l-7.61882,2.5374Z"
          transform="translate(-178 -137.70421)"
          fill="#2f2e41"
        />
        <path
          d="M549.19147,452.58108H469.2581V418.89133c0-11.546,8.13431-25.42745,18.13258-30.94445,14.03328-7.74228,29.37521-7.87832,42.09123-.37136a40.18354,40.18354,0,0,1,19.70956,34.4428Z"
          transform="translate(-178 -137.70421)"
          fill="#2f2e41"
        />
        <circle
          cx="510.80766"
          cy="422.87938"
          r="28.87125"
          transform="translate(-283.26396 530.54671) rotate(-61.33675)"
          fill="#ffb8b8"
        />
        <path
          d="M538.25017,424.3693h-41.189l-.09757-.46779a65.962,65.962,0,0,0-1.845-7.26646,82.63745,82.63745,0,0,0-4.51887,7.43177l-.16788.30248H482.4244a3.53543,3.53543,0,0,1-2.60984-1.15426c-.21782-.23992-.72751-.80126,1.38155-26.54669a3.55586,3.55586,0,0,1,2.01578-2.87731c18.07259-8.43678,36.38683-8.21351,54.43819.66695h.322l.11307.4477c3.5612,27.40248,3.04893,27.99424,2.83025,28.24679A3.52542,3.52542,0,0,1,538.25017,424.3693Z"
          transform="translate(-178 -137.70421)"
          fill="#2f2e41"
        />
      </svg>
    );
  }
}
