import React from "react";
import "./Button.css";

const onMouseEnter = (event, color, bgColor) => {
  const el = event.target;
  el.style.color = color;
  el.style.backgroundColor = bgColor;
};

const onMouseOut = (event, color, bgColor) => {
  const el = event.target;
  el.style.color = color;
  el.style.backgroundColor = bgColor;
};

export default function Button({ text, className, type, href, newTab, theme }) {
  const isLink = type !== "submit"; // Utiliser un <a> si le type n'est pas "submit"

  return (
    <div className={className}>
      {isLink ? (
        <a
          className="main-button"
          href={href}
          target={newTab ? "_blank" : "_self"}
          style={{
            color: theme.body,
            backgroundColor: theme.text,
            border: `solid 1px ${theme.text}`,
          }}
          onMouseEnter={(event) => onMouseEnter(event, theme.text, theme.body)}
          onMouseOut={(event) => onMouseOut(event, theme.body, theme.text)}
        >
          {text}
        </a>
      ) : (
        <button
          className="main-button"
          type="submit"
          style={{
            color: theme.body,
            backgroundColor: theme.text,
            border: `solid 1px ${theme.text}`,
          }}
          onMouseEnter={(event) => onMouseEnter(event, theme.text, theme.body)}
          onMouseOut={(event) => onMouseOut(event, theme.body, theme.text)}
        >
          {text}
        </button>
      )}
    </div>
  );
}
