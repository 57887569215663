import React from "react";
import "./CompetenceCard.css";

export default function CompetenceCard({ repo, theme, color }) {
  return (
    <div className="card-container">
      <div className="card-inner">
        {/* Face avant de la carte */}

        <div
          className="card-front"
          style={{ backgroundColor: color, color: theme.text }}
        >
          <h1>{repo.id}</h1>
          <div className="card-title">{repo.name}</div>
        </div>
        {/* Face arrière de la carte */}
        <div
          className="card-back"
          style={{ backgroundColor: color, color: theme.text }}
        >
          <div className="card-content">
            <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
              {repo.description.map((item, index) => (
                <li key={index} style={{ marginBottom: "0.75rem" }}>
                  {item} {/* Emoji inclus dans l'item */}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
