import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { Fade } from "react-reveal";
import "./ContactComponent.css";
import { greeting, contactPageData } from "../../portfolio.js";
import ContactForm from "./ContactForm";

const ContactData = contactPageData.contactSection;
const addressSection = contactPageData.addressSection;
const phoneSection = contactPageData.phoneSection;

class Contact extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="contact-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade bottom duration={1000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-img-ines">
                <img
                  src={require(`../../assets/images/${ContactData["profile_image_path"]}`)}
                  alt=""
                />
              </div>
              <div className="contact-heading-text-div">
                <h1
                  className="contact-heading-text"
                  style={{ color: theme.text }}
                >
                  {ContactData["title"]}
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {ContactData["description"]}
                </p>
                <SocialMedia theme={theme} />
                <div className="resume-btn-div">
                  <Button
                    text="📝 Voir mon CV"
                    newTab={true}
                    href={greeting.resumeLink}
                    theme={theme}
                  />
                </div>
              </div>
            </div>
          </Fade>
          <div className="divider" />
          <Fade bottom duration={1000} distance="40px">
            <div className="address-heading-div">
              <div className="contact-heading-img-div">
                {
                  <img
                    src={require(`../../assets/images/${addressSection["avatar_image_path"]}`)}
                    alt=""
                  />
                }
                {/*<AddressImg theme={theme} />*/}
              </div>
              <div className="address-heading-text-div">
                <div className="contact-heading-text-localisation">
                  <i
                    className="fa-solid fa-location-dot"
                    aria-hidden="true"
                  ></i>
                  <h1
                    className="address-heading-text"
                    style={{ color: theme.text }}
                  >
                    {addressSection["title"]}
                  </h1>
                  <p
                    className="contact-header-detail-text-localisation subTitle"
                    style={{ color: theme.secondaryText }}
                  >
                    {addressSection["subtitle"]}
                  </p>
                </div>
                <div className="contact-heading-text-contact">
                  <h1
                    className="address-heading-text-phone"
                    style={{ color: theme.text }}
                  >
                    {phoneSection["title"]}
                  </h1>
                  <p
                    className="contact-header-detail-text subTitle"
                    style={{ color: theme.secondaryText }}
                  >
                    <a
                      href={`tel:${phoneSection["subtitle"]}`}
                      className="phone-link"
                    >
                      <i className="fa fa-phone" aria-hidden="true"></i>
                      {phoneSection["subtitle"]}
                    </a>
                  </p>
                </div>
                <div className="address-btn-div">
                  <Button
                    text="⭐ Me contacter"
                    newTab={true}
                    href="mailto:belhout.ines69005@gmail.com"
                    theme={theme}
                  />
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <ContactForm theme={theme} />
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Contact;
