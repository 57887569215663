// Snackbar.js
import React, { Component } from "react";
import "./Snackbar.css";

class Snackbar extends Component {
  componentDidMount() {
    // Fermer la snackbar automatiquement après 3 secondes
    this.timer = setTimeout(() => {
      this.props.onClose();
    }, 3000);
  }

  componentWillUnmount() {
    // Nettoyage du timer lorsque le composant est démonté
    clearTimeout(this.timer);
  }

  render() {
    const { message, type } = this.props;
    return <div className={`snackbar snackbar-${type}`}>{message}</div>;
  }
}

export default Snackbar;
