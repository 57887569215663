import React, { Component } from "react";
import "./Educations.css";
import DegreeCard from "../../components/degreeCard/DegreeCard.js";
import { degrees } from "../../portfolio";
import { Fade } from "react-reveal";
import CompetencesMBA from "../../shared/opensource/projects.json";
import CompetencesBachelor from "../../shared/opensource/competences-bachelor.json";
import CompetencesBts from "../../shared/opensource/competences-bts.json";
import CompetenceCard from "../../components/competenceCard/CompetenceCard";

class Educations extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="main" id="educations">
        <div className="educations-header-div">
          <Fade bottom duration={2000} distance="20px">
            <h1 className="educations-header" style={{ color: theme.text }}>
              Formations
            </h1>
          </Fade>
        </div>
        <div className="educations-body-div">
          {degrees.degrees.map((degree) => {
            return <DegreeCard degree={degree} theme={theme} />;
          })}
        </div>
        <h1 className="educations-header" style={{ color: theme.text }}>
          Compétences acquises MBA Business Manager
        </h1>
        <div className="repo-cards-div-main">
          {CompetencesMBA.data.map((repo) => {
            return (
              <CompetenceCard
                theme={theme}
                repo={repo}
                color={theme.highlight}
              />
            );
          })}
        </div>

        <h1 className="educations-header" style={{ color: theme.text }}>
          Bachelor Responsable Commercial et Marketing
        </h1>
        <div className="repo-cards-div-main">
          {CompetencesBachelor.data.map((repo) => {
            return <CompetenceCard theme={theme} repo={repo} color="#ecdbd1" />;
          })}
        </div>

        <h1 className="educations-header" style={{ color: theme.text }}>
          BTS Management Commercial Opérationnel
        </h1>
        <div className="repo-cards-div-main">
          {CompetencesBts.data.map((repo) => {
            return <CompetenceCard theme={theme} repo={repo} color="#e8a3b8" />;
          })}
        </div>
      </div>
    );
  }
}

export default Educations;
