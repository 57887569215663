import * as React from "react";
import ExperienceCard from "../../components/experienceCard/ExperienceCard.js";
import "./ExperienceAccordion.css";
import { Accordion, Panel } from "baseui/accordion";

class ExperienceAccordion extends React.Component {
  render() {
    const theme = this.props.theme;

    // Création de l'état initial avec tous les panneaux ouverts par défaut
    const initialState = {
      expanded: this.props.sections.map((section) => section.title), // Déplier tous les panneaux
    };

    return (
      <div className="experience-accord">
        <Accordion
          initialState={initialState} // Appliquer l'état initial ici
          onChange={({ expanded }) => {
            // Gestion du changement d'état d'expansion des panneaux
            this.setState({ expandedPanels: expanded });
          }}
        >
          {this.props.sections.map((section) => (
            <Panel
              className="accord-panel"
              title={section.title}
              key={section.title}
              overrides={{
                Header: {
                  style: () => ({
                    backgroundColor: theme.body,
                    border: `1px solid`,
                    borderRadius: `5px`,
                    borderColor: theme.headerColor,
                    marginBottom: `3px`,
                    fontFamily: "Google Sans Regular",
                    color: theme.text,
                    ":hover": {
                      color: theme.secondaryText,
                    },
                  }),
                },
                Content: {
                  style: () => ({
                    backgroundColor: theme.body,
                  }),
                },
              }}
            >
              {section.experiences.map((experience, index) => (
                <ExperienceCard
                  index={index}
                  totalCards={section.experiences.length}
                  experience={experience}
                  theme={theme}
                />
              ))}
            </Panel>
          ))}
        </Accordion>
      </div>
    );
  }
}

export default ExperienceAccordion;
