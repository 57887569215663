import React, { Component } from "react";
import emailjs from "emailjs-com";
import "./ContactForm.css";
import Button from "../../components/button/Button";
import Snackbar from "./Snackbar";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nom: "",
      email: "",
      message: "",
      snackbar: { open: false, message: "", type: "" },
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_jjgv0cg",
        "template_9t6qd1m",
        e.target,
        "8md4LIwiyEvvcwjIP"
      )
      .then(
        (result) => {
          console.log(result.text);
          this.showSnackbar("Message envoyé avec succès !", "success");
        },
        (error) => {
          console.log(error.text);
          this.showSnackbar(
            "Une erreur est survenue, veuillez réessayer.",
            "error"
          );
        }
      );

    this.setState({
      nom: "",
      email: "",
      message: "",
    });
  };

  showSnackbar = (message, type) => {
    this.setState({ snackbar: { open: true, message, type } });
  };

  closeSnackbar = () => {
    this.setState({ snackbar: { open: false, message: "", type: "" } });
  };

  render() {
    const { theme } = this.props;
    const { snackbar } = this.state;

    return (
      <div className="contact-form-container">
        <form
          onSubmit={this.handleSubmit}
          className="contact-form"
          style={{ backgroundColor: theme.headerColor }}
        >
          <h2>✉️ Contacter moi</h2>
          <label>
            <div className="contact-form-title">Nom</div>
              <input
                type="text"
              name="nom"
              value={this.state.nom}
              onChange={this.handleChange}
              required
            />
          </label>
          <label>
            <div className="contact-form-title">Email</div>
            <input
              className="contact-form-title"
              type="email"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              required
            />
          </label>
          <label>
            <div className="contact-form-title">Message</div>
              <textarea
                className="contact-form-message"
              name="message"
              value={this.state.message}
              onChange={this.handleChange}
              required
            />
          </label>
          <Button
            className={"contact-form-button"}
            type="submit"
            text="Envoyer"
            newTab={false}
            theme={theme}
          />
        </form>
        {snackbar.open && (
          <Snackbar
            message={snackbar.message}
            type={snackbar.type}
            onClose={this.closeSnackbar}
          />
        )}
      </div>
    );
  }
}

export default ContactForm;
