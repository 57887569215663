import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import { Fade } from "react-reveal";
import { projectsHeader } from "../../portfolio.js";
import "./Competences.css";
import ProjectsImg from "./ProjectsImg";
import Certifications from "../../containers/certifications/Certifications";

const certifications = {
  certifications: [
    {
      title: "JIRA",
      subtitle: "Logiciel de gestion de projet",
      logo_path: "jira.png",
      certificate_link: "https://www.atlassian.com/fr/software/jira",
      alt_name: "JIRA",
      color_code: "#ffffff",
    },
    {
      title: "SAP",
      subtitle: "ERP pour la gestion des entreprises",
      logo_path: "sap.png",
      certificate_link: "https://www.sap.com/france/index.html",
      alt_name: "SAP",
      color_code: "#ffffff",
    },
    {
      title: "Excel",
      subtitle: "Tableur pour l'analyse de données",
      logo_path: "excel.png",
      certificate_link: "https://www.microsoft.com/fr-fr/microsoft-365/excel",
      alt_name: "Microsoft Excel",
      color_code: "#ffffff",
    },
    {
      title: "Miro",
      subtitle: "Outil de collaboration visuelle",
      logo_path: "miro.png",
      certificate_link: "https://www.microsoft.com/fr-fr/microsoft-365/excel",
      alt_name: "Miro",
      color_code: "#ffffff",
    },
    {
      title: "PowerPoint",
      subtitle: "Création de présentations",
      logo_path: "powerpoint.png",
      certificate_link:
        "https://www.microsoft.com/fr-fr/microsoft-365/powerpoint",
      alt_name: "Microsoft PowerPoint",
      color_code: "#ffffff",
    },
    {
      title: "Teams",
      subtitle: "Collaboration et communication",
      logo_path: "teams.png",
      certificate_link: "https://teams.microsoft.com/v2/",
      alt_name: "Microsoft Teams",
      color_code: "#ffffff",
    },
    {
      title: "PowerBI",
      subtitle: "Analyse et visualisation de données",
      logo_path: "powerbi.png",
      certificate_link:
        "https://www.microsoft.com/fr-fr/power-platform/products/power-bi",
      alt_name: "Microsoft PowerBI",
      color_code: "#ffffff",
    },
    {
      title: "SQL",
      subtitle: "Langage de requête pour bases de données",
      logo_path: "sql.png",
      certificate_link: "https://sql.sh/",
      alt_name: "SQL",
      color_code: "#ffffff",
    },
    {
      title: "Canva",
      subtitle: "Design graphique et création visuelle",
      logo_path: "canva.png",
      certificate_link: "https://www.canva.com/",
      alt_name: "Canva",
      color_code: "#ffffff",
    },
    {
      title: "Word",
      subtitle: "Traitement de texte",
      logo_path: "word.png",
      certificate_link: "https://www.microsoft.com/fr-fr/microsoft-365/word",
      alt_name: "Microsoft Word",
      color_code: "#ffffff",
    },
    {
      title: "SharePoint",
      subtitle: "Gestion de contenu et collaboration",
      logo_path: "sharepoint.png",
      certificate_link:
        "https://www.microsoft.com/fr-fr/microsoft-365/sharepoint/collaboration",
      alt_name: "Microsoft SharePoint",
      color_code: "#ffffff",
    },
    {
      title: "Outlook",
      subtitle: "Messagerie électronique",
      logo_path: "outlook.png",
      certificate_link:
        "https://www.microsoft.com/fr-fr/microsoft-365/outlook/email-and-calendar-software-microsoft-outlook",
      alt_name: "Microsoft Outlook",
      color_code: "#ffffff",
    },
  ],
};

class Competences extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="projects-main">
        <Header theme={theme} />
        <div className="basic-projects">
          <Fade bottom duration={2000} distance="40px">
            <div className="projects-heading-div">
              <div className="projects-heading-img-div">
                {/* <img
											src={require(`../../assets/images/${projectsHeader["avatar_image_path"]}`)}
											alt=""
										/> */}
                <ProjectsImg theme={theme} />
              </div>
              <div className="projects-heading-text-div">
                <h1
                  className="projects-heading-text"
                  style={{ color: theme.text }}
                >
                  {projectsHeader.title}
                </h1>
                <p
                  className="projects-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  {projectsHeader["description"]}
                </p>
              </div>
            </div>
          </Fade>
        </div>
        {/*<div className="repo-cards-div-main">*/}
        <div>
          {certifications.certifications.length > 0 ? (
            <Certifications theme={this.props.theme} />
          ) : null}
        </div>
        {/*<Button*/}
        {/*  text={"More Competences"}*/}
        {/*  className="project-button"*/}
        {/*  href={greeting.githubProfile}*/}
        {/*  newTab={true}*/}
        {/*  theme={theme}*/}
        {/*/>*/}

        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Competences;
