import React, { Component } from "react";

export default class MarketingImg extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width="804"
        height="497.68253"
        viewBox="0 0 804 697.68253"
      >
        <path
          id="e9a96f1c-287a-42d8-bbee-a97ef2edbe89-134"
          data-name="Path 22"
          d="M867.81723,266.44171h-3.82147V161.75244a60.59373,60.59373,0,0,0-60.59369-60.59371H581.59622a60.5937,60.5937,0,0,0-60.5937,60.59371V736.09587a60.59371,60.59371,0,0,0,60.5937,60.5937H803.402a60.59371,60.59371,0,0,0,60.59275-60.5937V340.96159h3.82147Z"
          transform="translate(-198 -101.15873)"
          fill="#e6e6e6"
        />
        <path
          id="bb6cf65b-d1a0-4b47-beae-738e5a961a87-135"
          data-name="Path 23"
          d="M805.847,116.91723H776.89634a21.49818,21.49818,0,0,1-19.90685,29.61745h-127.07a21.4982,21.4982,0,0,1-19.905-29.61745H582.97278A45.25079,45.25079,0,0,0,537.722,162.168V735.67748a45.25081,45.25081,0,0,0,45.25079,45.25077H805.847a45.25079,45.25079,0,0,0,45.25078-45.25077h0V162.16709a45.25078,45.25078,0,0,0-45.2508-45.24986Z"
          transform="translate(-198 -101.15873)"
          fill="#fff"
        />
        <path
          d="M626.99407,250.68916a8.60557,8.60557,0,0,1-6.42719-2.7982,10.72149,10.72149,0,0,1-2.54354-8.20821,12.4083,12.4083,0,0,1,3.3276-7.48316,9.99135,9.99135,0,0,1,7.23642-3.2061,9.01536,9.01536,0,0,1,6.721,2.96234,10.52249,10.52249,0,0,1,2.58867,8.04429l0,0a11.95108,11.95108,0,0,1-3.59235,7.84064A10.62651,10.62651,0,0,1,626.99407,250.68916Zm1.59329-18.07973c-3.53906,0-6.59713,3.24627-6.962,7.39046a7.11514,7.11514,0,0,0,1.60818,5.44892,4.964,4.964,0,0,0,3.76056,1.6244c3.30951,0,6.85055-2.28529,7.30105-7.39085a6.90374,6.90374,0,0,0-1.65351-5.28454A5.43891,5.43891,0,0,0,628.58736,232.60943Zm7.50873,7.23184h0Z"
          transform="translate(-198 -101.15873)"
          fill="#3f3d56"
        />
        <path
          d="M627.11159,261.53694a19.72992,19.72992,0,0,1-19.93616-21.85336,23.96089,23.96089,0,0,1,23.44137-21.538c6.50929,0,11.86513,2.0914,15.48846,6.04811,3.4358,3.75184,5.04462,9.03346,4.53012,14.8718-.487,5.53073-2.83343,8.41125-4.71617,9.8538a7.92326,7.92326,0,0,1-5.99839,1.71947c-2.97625-.52769-6.26788-3.23126-5.75006-9.11413l.95825-10.87625a1.808,1.808,0,0,1,3.602.31737l-.95823,10.87608c-.25744,2.92466.78153,4.88233,2.77919,5.23649A4.36287,4.36287,0,0,0,643.72,246.049c1.30644-1.001,2.94177-3.08014,3.31337-7.30075.43056-4.88585-.81253-9.07424-3.59484-12.1125-2.92008-3.18872-7.35377-4.87421-12.82175-4.87421A20.33216,20.33216,0,0,0,610.77758,239.999a16.11626,16.11626,0,0,0,16.334,17.922,18.145,18.145,0,0,0,8.25818-1.49734,1.808,1.808,0,0,1,1.40067,3.33367A21.691,21.691,0,0,1,627.11159,261.53694Z"
          transform="translate(-198 -101.15873)"
          fill="#3f3d56"
        />
        <path
          d="M608.02218,519.964a1.808,1.808,0,0,1-1.27843-3.08637l11.75182-11.75182a1.808,1.808,0,0,1,2.55686,2.5568l-11.75182,11.75182A1.80221,1.80221,0,0,1,608.02218,519.964Zm29.83154-4.51993a1.808,1.808,0,0,1-1.27843-3.08637l9.94385-9.94385a1.808,1.808,0,0,1,2.55686,2.5568l-9.94385,9.94385A1.80217,1.80217,0,0,1,637.85372,515.44408Zm-5.42391,0a1.80221,1.80221,0,0,1-1.27844-.52957l-7.23189-7.23189a1.808,1.808,0,1,1,2.55687-2.5568l7.23189,7.23189a1.808,1.808,0,0,1-1.27843,3.08637Z"
          transform="translate(-198 -101.15873)"
          fill="#3f3d56"
        />
        <path
          d="M650.50953,505.50023a4.51993,4.51993,0,1,1,4.51993-4.51993A4.525,4.525,0,0,1,650.50953,505.50023Zm0-5.42392a.904.904,0,1,0,.904.904A.905.905,0,0,0,650.50953,500.07631Z"
          transform="translate(-198 -101.15873)"
          fill="#3f3d56"
        />
        <path
          d="M635.14176,520.868a4.51993,4.51993,0,1,1,4.51994-4.51993A4.525,4.525,0,0,1,635.14176,520.868Zm0-5.42392a.904.904,0,1,0,.904.904A.905.905,0,0,0,635.14176,515.44408Z"
          transform="translate(-198 -101.15873)"
          fill="#3f3d56"
        />
        <path
          d="M622.486,508.21219a4.51993,4.51993,0,1,1,4.51993-4.51993A4.525,4.525,0,0,1,622.486,508.21219Zm0-5.42392a.904.904,0,1,0,.904.904A.905.905,0,0,0,622.486,502.78827Z"
          transform="translate(-198 -101.15873)"
          fill="#3f3d56"
        />
        <path
          d="M605.31022,525.38793a4.51993,4.51993,0,1,1,4.51993-4.51993A4.525,4.525,0,0,1,605.31022,525.38793Zm0-5.42392a.904.904,0,1,0,.904.904A.905.905,0,0,0,605.31022,519.964Z"
          transform="translate(-198 -101.15873)"
          fill="#3f3d56"
        />
        <path
          d="M776.89359,664.81185H738.92616a6.33508,6.33508,0,0,1-6.3279-6.32791V631.36436a6.33508,6.33508,0,0,1,6.3279-6.32791h37.96743a6.33509,6.33509,0,0,1,6.3279,6.32791v27.11958A6.33509,6.33509,0,0,1,776.89359,664.81185ZM738.92616,628.6524a2.715,2.715,0,0,0-2.712,2.712v27.11958a2.715,2.715,0,0,0,2.712,2.712h37.96743a2.715,2.715,0,0,0,2.712-2.712V631.36436a2.715,2.715,0,0,0-2.712-2.712Z"
          transform="translate(-198 -101.15873)"
          fill="#3f3d56"
        />
        <path
          d="M757.90987,648.54009a1.805,1.805,0,0,1-1.11-.38087l-16.27175-12.65581a1.80795,1.80795,0,1,1,2.22-2.8542l15.16177,11.79243,15.16177-11.79243a1.80795,1.80795,0,1,1,2.22,2.8542l-16.27175,12.65581A1.805,1.805,0,0,1,757.90987,648.54009Z"
          transform="translate(-198 -101.15873)"
          fill="#3f3d56"
        />
        <path
          d="M961.83465,689.84127l-209.14131.08288a1,1,0,0,1,0-2l209.14131-.08288a1,1,0,0,1,0,2Z"
          transform="translate(-198 -101.15873)"
          fill="#ccc"
        />
        <circle cx="761.05118" cy="587.68253" r="14" fill="#8b5599" />
        <path
          d="M956.83465,421.84127l-211.14131.08288a1,1,0,0,1,0-2l211.14131-.08288a1,1,0,0,1,0,2Z"
          transform="translate(-198 -101.15873)"
          fill="#ccc"
        />
        <circle cx="761.05118" cy="320.68253" r="14" fill="#8b5599" />
        <path
          d="M416.26772,284.84127l211.1413.08288a1,1,0,0,0,0-2l-211.1413-.08288a1,1,0,1,0,0,2Z"
          transform="translate(-198 -101.15873)"
          fill="#ccc"
        />
        <circle cx="216.05118" cy="183.68253" r="14" fill="#8b5599" />
        <path
          d="M416.26772,555.84127l211.1413.08288a1,1,0,0,0,0-2l-211.1413-.08288a1,1,0,1,0,0,2Z"
          transform="translate(-198 -101.15873)"
          fill="#ccc"
        />
        <circle cx="216.05118" cy="454.68253" r="14" fill="#8b5599" />
        <path
          d="M757.90987,689.92415a45,45,0,1,1,45-45A45.05109,45.05109,0,0,1,757.90987,689.92415Zm0-88a43,43,0,1,0,43,43A43.04864,43.04864,0,0,0,757.90987,601.92415Z"
          transform="translate(-198 -101.15873)"
          fill="#ccc"
        />
        <path
          d="M627.90987,555.92415a45,45,0,1,1,45-45A45.05109,45.05109,0,0,1,627.90987,555.92415Zm0-88a43,43,0,1,0,43,43A43.04864,43.04864,0,0,0,627.90987,467.92415Z"
          transform="translate(-198 -101.15873)"
          fill="#ccc"
        />
        <path
          d="M748.90987,421.92415a45,45,0,1,1,45-45A45.05109,45.05109,0,0,1,748.90987,421.92415Zm0-88a43,43,0,1,0,43,43A43.04864,43.04864,0,0,0,748.90987,333.92415Z"
          transform="translate(-198 -101.15873)"
          fill="#ccc"
        />
        <path
          d="M628.90987,284.84127a45,45,0,1,1,45-45A45.0511,45.0511,0,0,1,628.90987,284.84127Zm0-88a43,43,0,1,0,43,43A43.04864,43.04864,0,0,0,628.90987,196.84127Z"
          transform="translate(-198 -101.15873)"
          fill="#ccc"
        />
        <path
          d="M346.5023,589.73817a11.01912,11.01912,0,0,1-4.302-7.88086,10.26913,10.26913,0,0,1-.02539-1.08007l-46.56811-31.36231a8.55177,8.55177,0,0,1-3.33667-9.66992l22.25684-55.2959a9.69886,9.69886,0,0,1,18.02343,7.1709l-17.96972,45.69043L350.8419,570.246a10.46216,10.46216,0,0,1,1.45312-.22461,11.00091,11.00091,0,0,1,2.9834,21.76269,11.40682,11.40682,0,0,1-2.14673.20606A10.86469,10.86469,0,0,1,346.5023,589.73817Z"
          transform="translate(-198 -101.15873)"
          fill="#a0616a"
        />
        <path
          d="M295.62572,517.00863a4.8117,4.8117,0,0,1,.10142-4.00962l10.62461-21.716a13.37737,13.37737,0,0,1,25.5217,8.02829l-3.879,23.94191a4.81688,4.81688,0,0,1-6.21361,3.82l-23.18522-7.368A4.81145,4.81145,0,0,1,295.62572,517.00863Z"
          transform="translate(-198 -101.15873)"
          fill="#8b5599"
        />
        <polygon
          points="170.908 683.95 183.168 683.95 189 636.662 170.906 636.663 170.908 683.95"
          fill="#a0616a"
        />
        <path
          d="M365.78058,781.10634l24.1438-.001h.001a15.38605,15.38605,0,0,1,15.38647,15.38623v.5l-39.53051.00147Z"
          transform="translate(-198 -101.15873)"
          fill="#2f2e41"
        />
        <polygon
          points="51.025 675.264 62.899 678.315 80.319 633.967 62.794 629.464 51.025 675.264"
          fill="#a0616a"
        />
        <path
          d="M246.99251,771.76721l23.384,6.00926.00095.00024a15.386,15.386,0,0,1,11.072,18.73209l-.12447.48426L243.03848,787.154Z"
          transform="translate(-198 -101.15873)"
          fill="#2f2e41"
        />
        <circle cx="137.01471" cy="334.98029" r="24.56103" fill="#a0616a" />
        <path
          d="M322.59238,538.2997a88.72978,88.72978,0,0,1-14.73608-20.23438,32.71635,32.71635,0,0,1-1.54028-26.47656A33.44218,33.44218,0,0,1,325.30674,472.244a32.87972,32.87972,0,0,1,41.6123,15.44629c6.14576,12.02734,8.5542,23.78613,7.1587,34.95215-2.66822,21.3457-4.23023,32.37891-4.64258,32.791l-.13721.13769-45.68945,2.03027Z"
          transform="translate(-198 -101.15873)"
          fill="#8b5599"
        />
        <path
          d="M325.306,551.69409l40.88708-2.03514a4,4,0,0,1,4.183,3.63962l18.8086,210.83184a4,4,0,0,1-4.06926,4.35453l-15.97385-.33987a4,4,0,0,1-3.79715-3.03562L334.82823,642.1471a4,4,0,0,0-7.5156-.70944L269.48285,767.03672a4,4,0,0,1-4.643,2.19755l-16.0757-4.19366a3.9948,3.9948,0,0,1-2.90489-4.693c5.28922-24.96831,36.97167-167.9555,76.86151-207.5331A3.94084,3.94084,0,0,1,325.306,551.69409Z"
          transform="translate(-198 -101.15873)"
          fill="#2f2e41"
        />
        <path
          d="M402.73325,575.3661a11.01841,11.01841,0,0,1-6.75781-5.91308,10.35271,10.35271,0,0,1-.39673-1.00391l-54.531-13.36328a8.55185,8.55185,0,0,1-6.469-7.92481l1.80469-59.57812a9.69885,9.69885,0,0,1,19.39087.50879l-1.09644,49.084,45.40088,18.39746a10.744,10.744,0,0,1,1.28516-.71289h.00049a11.01049,11.01049,0,1,1,1.36889,20.50586Z"
          transform="translate(-198 -101.15873)"
          fill="#a0616a"
        />
        <path
          d="M330.56717,517.26975a4.81171,4.81171,0,0,1-1.25291-3.81019L332.017,489.4354a13.37737,13.37737,0,0,1,26.7351-1.02221l4.39856,23.85193a4.81687,4.81687,0,0,1-4.567,5.6871l-24.31266.85825A4.81149,4.81149,0,0,1,330.56717,517.26975Z"
          transform="translate(-198 -101.15873)"
          fill="#8b5599"
        />
        <path
          d="M299.82094,468.56546l2.56631-8.29416,1.75489,9.30867,14.413-.38175-.06644-9.17783,5.479,9.05734,13.0608-2.88663c-2.79026-3.718-5.6137-7.49394-7.3982-11.87661s-2.43414-9.52219-.76677-13.95851c4.12231-10.96814,19.75146-7.89894,28.29987-7.99195,2.49388-.02713,5.30932-.20677,6.94257-2.24571,1.22984-1.53532,1.43135-3.73159,1.369-5.762-.321-10.45468-6.59769-20.42873-15.47137-24.58506a23.981,23.981,0,0,0-13.9051-1.88355,25.13925,25.13925,0,0,0-7.09156,2.23369c-1.99719.97562-4.09482,3.29907-6.24511,3.23616-1.80717-.05286-4.19946-1.935-6.16588-2.2566a19.05966,19.05966,0,0,0-7.1235.24862,22.37477,22.37477,0,0,0-12.07628,7.22647c-6.68033,7.76217-8.66524,19.20034-7.50091,29.73068s5.10364,20.43362,8.99909,30.16588"
          transform="translate(-198 -101.15873)"
          fill="#2f2e41"
        />
        <path
          d="M1001,798.84127H199a1,1,0,0,1,0-2h802a1,1,0,0,1,0,2Z"
          transform="translate(-198 -101.15873)"
          fill="#e6e6e6"
        />
        <path
          d="M987.47028,786.44789a19.3089,19.3089,0,0,0,6.37547-9.59647c.81354-3.81863-.81353-8.3014-4.44955-9.77908-4.08429-1.57729-8.45082,1.26183-11.75479,4.11747s-7.106,6.12644-11.43933,5.51214a17.38311,17.38311,0,0,0,5.32951-16.28725,6.64138,6.64138,0,0,0-1.49426-3.32056c-2.258-2.42405-6.37547-1.37806-9.09834.5313-8.63346,6.07658-11.04087,17.79816-11.07407,28.35763-.88-3.81863-.14942-7.78672-.166-11.622s-1.09579-8.3014-4.38314-10.3269a13.28241,13.28241,0,0,0-6.64112-1.57728c-3.885-.13286-8.20179.24908-10.85824,3.08809-3.32056,3.51979-2.44061,9.447.43168,13.28225s7.22222,6.309,11.2235,8.99869a24.77172,24.77172,0,0,1,8.03575,7.65387,7.32233,7.32233,0,0,1,.5977,1.36145h24.32312A67.68756,67.68756,0,0,0,987.47028,786.44789Z"
          transform="translate(-198 -101.15873)"
          fill="#f2f2f2"
        />
        <path
          d="M734.40527,403.3608a2.7185,2.7185,0,0,1-2.47036-3.82532l3.12891-6.981a23.40683,23.40683,0,1,1,8.84917,4.92855l-7.98548,5.40606A2.71035,2.71035,0,0,1,734.40527,403.3608Zm16.14591-48.267a20.04564,20.04564,0,0,0-12.48787,35.73546l.98571.78613-3.36661,7.51194,7.72581-5.2303.76284.25622a20.05171,20.05171,0,1,0,6.38012-39.05945Z"
          transform="translate(-198 -101.15873)"
          fill="#3f3d56"
        />
        <circle cx="552.55118" cy="272.90814" r="3.25253" fill="#3f3d56" />
        <circle cx="543.33569" cy="272.90814" r="3.25253" fill="#3f3d56" />
        <circle cx="561.76668" cy="272.90814" r="3.25253" fill="#3f3d56" />
      </svg>
    );
  }
}
