import React, { Component } from "react";

export default class ExperienceImg extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width="992.41033"
        height="518.95341"
        viewBox="0 0 992.41033 518.95341"
      >
        <path
          d="M180.36142,682.07656c-15.64549,8.43632-22.36178,26.34122-22.36178,26.34122s18.6501,4.22692,34.29563-4.20939,22.36178-26.34122,22.36178-26.34122S196.007,673.64026,180.36142,682.07656Z"
          transform="translate(-103.79483 -190.52329)"
          fill="#f1f1f1"
        />
        <path
          d="M185.50867,687.87517c-9.16349,15.231-27.36468,21.097-27.36468,21.097s-3.34425-18.82842,5.81924-34.05945,27.36468-21.097,27.36468-21.097S194.67216,672.64414,185.50867,687.87517Z"
          transform="translate(-103.79483 -190.52329)"
          fill="#f1f1f1"
        />
        <rect
          x="488.77407"
          y="11.42212"
          width="450.80615"
          height="2"
          fill="#cbcbcb"
        />
        <rect
          x="549.18081"
          y="12.17554"
          width="2"
          height="63.56348"
          fill="#cbcbcb"
        />
        <polygon
          points="715.205 126.123 612.156 126.123 612.156 12.113 614.156 12.113 614.156 124.123 715.205 124.123 715.205 126.123"
          fill="#cbcbcb"
        />
        <rect
          x="679.88467"
          y="12.17554"
          width="2"
          height="63.56348"
          fill="#cbcbcb"
        />
        <rect
          x="713.44277"
          y="125.17554"
          width="2"
          height="63.56348"
          fill="#cbcbcb"
        />
        <rect
          x="817.00063"
          y="12.17554"
          width="2"
          height="63.56348"
          fill="#cbcbcb"
        />
        <rect
          x="885.55874"
          y="12.17554"
          width="2"
          height="63.56348"
          fill="#cbcbcb"
        />
        <rect
          x="531.43219"
          y="85.46177"
          width="37.97815"
          height="14.79669"
          fill="#8b5599"
        />
        <rect
          x="661.43219"
          y="85.46177"
          width="37.97815"
          height="14.79669"
          fill="#3f3d56"
        />
        <rect
          x="799.43219"
          y="85.46177"
          width="37.97815"
          height="14.79669"
          fill="#cbcbcb"
        />
        <rect
          x="865.43219"
          y="85.46177"
          width="37.97815"
          height="14.79669"
          fill="#8b5599"
        />
        <rect
          x="954.43219"
          y="5.46177"
          width="37.97815"
          height="14.79669"
          fill="#3f3d56"
        />
        <rect
          x="695.43219"
          y="197.46177"
          width="37.97815"
          height="14.79669"
          fill="#3f3d56"
        />
        <rect x="414.98031" width="60.88189" height="23.72023" fill="#8b5599" />
        <polygon
          points="282.481 241.153 286.458 277.03 337.259 277.905 331.128 234.987 282.481 241.153"
          fill="#ffb7b7"
        />
        <path
          d="M363.3878,511.47268a8.21311,8.21311,0,0,0-2.22867-12.39509l2.35815-18.6197-10.96562-4.19277L349.5908,502.6065a8.25765,8.25765,0,0,0,13.797,8.86618Z"
          transform="translate(-103.79483 -190.52329)"
          fill="#ffb7b7"
        />
        <polygon
          points="351.976 507.436 362.715 507.436 367.823 466.017 351.974 466.018 351.976 507.436"
          fill="#ffb7b7"
        />
        <path
          d="M453.03205,694.45385l21.14737-.00086h.00086A13.47749,13.47749,0,0,1,487.657,707.92954v.43794l-34.62435.00128Z"
          transform="translate(-103.79483 -190.52329)"
          fill="#2f2e41"
        />
        <polygon
          points="229.353 507.436 240.091 507.436 245.199 466.017 229.351 466.018 229.353 507.436"
          fill="#ffb7b7"
        />
        <path
          d="M330.40834,694.45385l21.14737-.00086h.00086a13.47749,13.47749,0,0,1,13.47676,13.47655v.43794l-34.62435.00128Z"
          transform="translate(-103.79483 -190.52329)"
          fill="#2f2e41"
        />
        <path
          d="M348.60546,496.9351l4.72284-54.63581,13.77849-49.90141A18.84767,18.84767,0,0,1,383.378,378.73315l.44949-.0432,2.448,37.45429-17.6201,36.20633-5.82411,43.43665Z"
          transform="translate(-103.79483 -190.52329)"
          fill="#8b5599"
        />
        <path
          d="M389.44752,446.677l-7.233,13.01944s-28.20878,28.5171-30.37869,72.57132L325.64766,682.20716l24.27076-.59927,49.1408-140.53071,34.75813,59.62817,17.37906,77.90615,27.5668-5.09387-12.2852-90.491-23.67149-84.19857-2.39711-45.84477Z"
          transform="translate(-103.79483 -190.52329)"
          fill="#2f2e41"
        />
        <circle cx="310.71903" cy="148.57667" r="21.5126" fill="#ffb7b7" />
        <path
          d="M329.59883,387.96168c3.99664-7.807,5.36072-16.67178,8.43679-24.88522,3.07629-8.21344,8.665-16.36241,17.13833-18.62605,4.80624-1.284,9.91356-.50017,14.85067-1.11148,4.34093-.53749,9.01459-2.75382,10.677-6.60917a27.22734,27.22734,0,0,1,1.40065-11.29587c3.09681-8.79909,10.48194-15.74538,19.09152-19.33621,6.99339-2.917,15.047-3.74085,22.18877-1.20777s13.13654,8.72393,14.42043,16.192c1.28389,7.46811-2.83294,15.80707-9.93067,18.4598-5.09621,1.90461-11.65935,1.27047-14.98711,5.57472-3.37095,4.36044-.95457,10.71519,2.10717,15.29808l-.20913.21266c-10.00039.11724-20.81037-1.81511-27.69811-9.066a21.94484,21.94484,0,0,1-2.05756-2.53159c-.21085.33846-.432.66857-.66974.98393-3.46847,4.60571-8.89826,7.2185-14.07145,9.764-5.17362,2.54554-10.53883,5.40237-13.64591,10.25919-5.615,8.7769-1.92925,20.59786-5.14025,30.51009a22.53818,22.53818,0,0,1-42.38777,1.37455C317.85266,402.65144,325.60218,395.76872,329.59883,387.96168Z"
          transform="translate(-103.79483 -190.52329)"
          fill="#2f2e41"
        />
        <path
          d="M467.35382,510.75892a8.21316,8.21316,0,0,0-7.70915-9.95863l-6.52159-17.59894-11.66143,1.35471,9.55881,24.72376a8.25764,8.25764,0,0,0,16.33336,1.4791Z"
          transform="translate(-103.79483 -190.52329)"
          fill="#ffb7b7"
        />
        <path
          d="M386.04414,451.97165l-6.22528-29.94941-1.95619-22.81317a53.714,53.714,0,0,1,10.18022-36.31795l1.98977-2.71789,33.50383,11.90825,16.33365,21.53228c9.96788,10.0222,2.16405,19.61714,1.07625,20.85313l-.33444,30.26247-.37615.05046Z"
          transform="translate(-103.79483 -190.52329)"
          fill="#8b5599"
        />
        <path
          d="M447.73748,504.4896l-24.91564-57.41229-3.62029-52.59964-.73-11.055a10.56595,10.56595,0,0,1,21.01565-2.0969l7.843,58.63588,13.50028,58.496Z"
          transform="translate(-103.79483 -190.52329)"
          fill="#8b5599"
        />
        <path
          d="M641.79483,709.25743h-537a1,1,0,0,1,0-2h537a1,1,0,0,1,0,2Z"
          transform="translate(-103.79483 -190.52329)"
          fill="#cbcbcb"
        />
      </svg>
    );
  }
}
