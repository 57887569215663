/* Change this file to get your personal Porfolio */

// Website related settings

const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Portfolio d'Inès",
  description:
    "Je suis une professionnelle passionnée, prête à m'engager dans des projets à fort impact, en développant des systèmes durables et évolutifs, tant sur le plan social que technique.",
  og: {
    title: "Inès Belhout Portfolio",
    type: "website",
    url: "http://ines-belhout.fr/",
  },
};

//Home Page
const greeting = {
  title: "Inès Belhout",
  logo_name: "Ines Belhout",
  nickname: "À propos de moi",
  subTitle:
    "Je suis une professionnelle passionnée, prête à m'engager dans des projets à fort impact, en développant des systèmes durables et évolutifs, tant sur le plan social que technique.",
  resumeLink: "/assets/CV-Ines-Belhout.pdf",
  portfolio_repository: "",
  githubProfile: "",
};

const socialMediaLinks = [
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/ines-b-6a863a220/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:belhout.ines69005@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
];

const skills = {
  data: [
    {
      title: "Développement commercial",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Élaboration de plans d'actions commerciales pour atteindre les objectifs de vente à court, moyen et long terme.",
        "💡 Analyse des parts de marché, des ventes et des marges bénéficiaires du produit ou service pour éclairer les décisions stratégiques.",
        "📈 Suivi des KPIs commerciaux à l’aide de tableau de bord et de grille d’analyse pour mesurer l'impact des stratégies, l'efficacité des ventes et l'évolution de la part de marché.",
        "🔍 Veille concurrentielle pour surveiller les concurrents et adapter les tendances, proposer des ajustements et rester compétitif sur le marché.",
        "🤝 Gestion de la relation client (CRM) en développant et entretenant des relations solides pour maximiser la satisfaction et la fidélité des clients.",
        "🚀 Identification de nouvelles opportunités d'affaires en explorant les marchés émergents et les segments à fort potentiel.",
        "🌐 Participation à des salons professionnels et événements de networking pour accroître la visibilité et établir de nouveaux contacts.",
      ],
      softwareSkills: [
        {
          skillName: "SAP",
          fontAwesomeClassname: "logos:sap",
          style: { backgroundColor: "transparent" },
        },
        {
          skillName: "Excel",
          fontAwesomeClassname: "vscode-icons:file-type-excel",
          style: { backgroundColor: "transparent" },
        },
        {
          skillName: "Word",
          fontAwesomeClassname: "vscode-icons:file-type-word",
          style: { backgroundColor: "transparent" },
        },
        {
          skillName: "Jira",
          fontAwesomeClassname: "devicon:jira-wordmark",
          style: { backgroundColor: "transparent", color: "#3776AB" },
        },
        {
          skillName: "Sharepoint",
          imageSrc: "sharepoint.png",
          style: { backgroundColor: "transparent", color: "#3776AB" },
        },
        {
          skillName: "Outlook",
          fontAwesomeClassname: "vscode-icons:file-type-outlook",
          style: { backgroundColor: "transparent", color: "#3776AB" },
        },
        {
          skillName: "Power BI",
          fontAwesomeClassname: "logos:microsoft-power-bi",
          style: { backgroundColor: "transparent" },
        },
      ],
    },
    {
      title: "Management des équipes",
      fileName: "FullStackImg",
      skills: [
        "👥 Encadrement et animation des équipes, fixation des objectifs, et communication efficace en adoptant une écoute active et en favorisant un feedback constructif.",
        "🌟 Motivation et engagement des équipes en reconnaissant les accomplissements et en offrant des opportunités de développement.",
        "📑 Définition des rôles et responsabilités pour clarifier les attentes et faciliter la collaboration.",
        "🎯 Gestion des performances en fixant des objectifs SMART et en réalisant des évaluations régulières.",
        "🤝 Utilisation d'outils d'animation et de collaboration pour favoriser la cohésion et améliorer la productivité.",
        "📚 Développement des compétences via des formations, du coaching et du mentorat.",
        "🤝 Gestion des conflits en identifiant les sources de tension et en facilitant des solutions amiables.",
        "🔄 Gestion du changement organisationnel en accompagnant les équipes à travers les transitions et en minimisant la résistance.",
      ],
      softwareSkills: [
        {
          skillName: "Microsoft Teams",
          fontAwesomeClassname: "logos:microsoft-teams",
          style: { color: "#6264A7" },
        },
        {
          skillName: "Miro",
          imageSrc: "miro.png",
          style: { color: "#2D8CFF" },
        },
        {
          skillName: "Jira",
          fontAwesomeClassname: "devicon:jira-wordmark",
          style: { color: "#0052CC" },
        },
        {
          skillName: "Slack",
          fontAwesomeClassname: "logos:slack-icon",
          style: { color: "#4A154B" },
        },
        {
          skillName: "Trello",
          fontAwesomeClassname: "devicon:trello",
          style: { color: "#0079BF" },
        },
        {
          skillName: "Klaxoon",
          imageSrc: "klaxoon.png",
          style: { color: "#0079BF" },
        },
      ],
    },
    {
      title: "Gestion de projet",
      fileName: "ProjectImg",
      skills: [
        "🛠️ Définition et pilotage de projets : constitution des équipes, découpage fonctionnel et ordonnancement temporel.",
        "📋 Élaboration de plans de projet détaillés incluant les livrables, les jalons et les dépendances.",
        "⚙️ Application des méthodologies de gestion de projet telles qu'Agile ou Scrum pour améliorer l'efficacité.",
        "📊 Évaluation du projet en vue de proposer des ajustements.",
        "📢 Communication régulière avec les parties prenantes pour assurer la transparence et gérer les attentes.",
        "💸 Gestion des ressources et du budget en optimisant les coûts.",
        "🔍 Contrôle de la qualité en mettant en place des indicateurs de performance et des processus d'audit.",
        "🛡️ Gestion des risques en identifiant les facteurs critiques et en élaborant des plans de contingence.",
      ],
      softwareSkills: [
        {
          skillName: "Jira",
          fontAwesomeClassname: "devicon:jira-wordmark",
          style: { color: "#0052CC" },
        },
        {
          skillName: "Excel",
          fontAwesomeClassname: "vscode-icons:file-type-excel",
          style: { color: "#3776AB" },
        },
        {
          skillName: "Trello",
          fontAwesomeClassname: "simple-icons:trello",
          style: { color: "#0079BF" },
        },
        {
          skillName: "Notion",
          fontAwesomeClassname: "simple-icons:notion",
          style: { color: "#000000" },
        },
        {
          skillName: "Slack",
          fontAwesomeClassname: "simple-icons:slack",
          style: { color: "#4A154B" },
        },
        {
          skillName: "Confluence",
          fontAwesomeClassname: "simple-icons:confluence",
          style: { color: "#172B4D" },
        },
        {
          skillName: "MS Teams",
          fontAwesomeClassname: "simple-icons:microsoftteams",
          style: { color: "#6264A7" },
        },
      ],
    },
    {
      title: "Marketing",
      fileName: "MarketingImg",
      skills: [
        "📊 Participation à la définition de la politique marketing de l'entreprise par des études de marché.",
        "🎨 Création de supports marketing attractifs pour promouvoir les produits et services.",
        "💡 Proposition d'axes stratégiques via le mix marketing pour aligner les promotions.",
        "🌐 Gestion des campagnes de marketing digital incluant le SEO, SEA, et réseaux sociaux.",
        "🤖 Utilisation des outils d'automatisation marketing pour optimiser les campagnes email.",
        "📝 Création de contenus engageants pour renforcer la position de l'entreprise.",
        "📅 Organisation d'événements marketing pour accroître la notoriété de la marque.",
        "🔄 Veille marketing pour rester informé des tendances et adapter les offres.",
      ],
      softwareSkills: [
        {
          skillName: "Google Analytics",
          fontAwesomeClassname: "simple-icons:googleanalytics",
          style: { color: "#FF9900" },
        },
        {
          skillName: "Mailchimp",
          fontAwesomeClassname: "simple-icons:mailchimp",
          style: { color: "#FFE01B" },
        },
        {
          skillName: "Canva",
          fontAwesomeClassname: "simple-icons:canva",
          style: { color: "#00C4CC" },
        },
        {
          skillName: "HubSpot",
          fontAwesomeClassname: "simple-icons:hubspot",
          style: { color: "#FF7A59" },
        },
        {
          skillName: "Adobe Photoshop",
          fontAwesomeClassname: "simple-icons:adobephotoshop",
          style: { color: "#31A8FF" },
        },
      ],
    },
    {
      title: "Négociation client",
      fileName: "NegotiationImg",
      skills: [
        "🧐 Préparation minutieuse en analysant les besoins du client.",
        "🤝 Développement de relations durables avec une communication régulière.",
        "🎯 Maîtrise des techniques de négociation pour des accords avantageux.",
        "📜 Négociation de contrats complexes en respectant les normes éthiques.",
        "✅ Clôture efficace en sécurisant les engagements écrits.",
      ],
      softwareSkills: [
        {
          skillName: "HubSpot",
          fontAwesomeClassname: "simple-icons:hubspot",
          style: { color: "#FF7A59" },
        },
        {
          skillName: "Salesforce",
          fontAwesomeClassname: "simple-icons:salesforce",
          style: { color: "#00A1E0" },
        },
        {
          skillName: "Zoom",
          fontAwesomeClassname: "simple-icons:zoom",
          style: { color: "#2D8CFF" },
        },
        {
          skillName: "LinkedIn Sales Navigator",
          fontAwesomeClassname: "simple-icons:linkedin",
          style: { color: "#0077B5" },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/layman_brother/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/ashutosh_1919",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@ashutosh391",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "MBA Manager de la Stratégie Commerciale et Marketing",
      subtitle: "ICL ÉCOLE DE COMMERCE ET MANAGEMENT",
      logo_path: "ICL_logo.png",
      alt_name: "ICL ÉCOLE DE COMMERCE ET MANAGEMENT",
      duration: "Octobre 2022 à octobre 2024",
      descriptions: [
        "⚡ Rendu de challenges intermédiaires réalisés en groupe et en individuel, avec un planning dédié pour chaque bloc.",
        "⚡ Validation des compétences pour chaque étape, incluant la structuration des dossiers finaux et le passage devant un jury pour la soutenance finale.",
        "⚡ Travail sur des cas concrets d'entreprises réelles, permettant une application directe des connaissances en situation professionnelle.",
        "⚡ Classement des prestations : 1ère place parmi 120 élèves pour les blocs 1 et 3.",
      ],
      website_link: "https://www.icl.fr/",
    },
    {
      title: "Bachelor Responsable Commercial & Marketing",
      subtitle: "ICL ÉCOLE DE COMMERCE ET MANAGEMENT",
      logo_path: "ICL_logo.png",
      alt_name: "ICL ÉCOLE DE COMMERCE ET MANAGEMENT",
      duration: "Septembre 2021 à septembre 2022",
      descriptions: [
        "⚡ Participation à des projets et challenges en groupe et en individuel, avec un calendrier de rendus pour chaque bloc de compétences.",
        "⚡ Validation progressive des compétences à travers la réalisation de dossiers structurés et la présentation finale devant un jury professionnel.",
        "⚡ Application des acquis sur des cas concrets d'entreprises, offrant une immersion dans des situations réelles du monde des affaires.",
      ],
      website_link: "https://www.icl.fr/",
    },
    {
      title: "BTS Management Commercial Opérationnel (MCO)",
      subtitle: "Alternance Rhône-Alpes Lyon",
      logo_path: "ALT_RHONES.png",
      alt_name: "ALTERNANCE RHÔNE-ALPES",
      duration: "Août 2019 à août 2021",
      descriptions: [
        "⚡ Développement des compétences en gestion de la relation client, pilotage de l’activité commerciale, gestion de l’offre produits/services et management de l’équipe commerciale.",
        "⚡ Réalisation de projets concrets en alternance, permettant d’appliquer directement les connaissances acquises en cours au sein de l’entreprise d'accueil.",
        "⚡ Validation des compétences par une soutenance et l’élaboration de dossiers de synthèse pour l’obtention du diplôme.",
      ],
      website_link:
        "https://www.groupe-alternance.com/regions/auvergne-rhone-alpes/",
    },
    {
      title: "Baccalauréat Commerce",
      subtitle: "Alternance Rhône-Alpes Lyon",
      logo_path: "ALT_RHONES.png",
      alt_name: "ALTERNANCE RHÔNE-ALPES",
      duration: "Août 2017 à août 2019",
      descriptions: [
        "⚡ Acquisition des fondamentaux du commerce, avec un accent sur la vente, la négociation, la prospection et le suivi client.",
        "⚡ Expérience en entreprise via l’alternance, offrant une première immersion dans les activités commerciales et une mise en pratique directe des cours théoriques.",
        "⚡ Réalisation de projets en lien avec la fidélisation client et la gestion de l’espace de vente, ainsi que l’organisation de promotions commerciales.",
        "⚡ Validation du diplôme par des évaluations pratiques et la présentation de dossiers en lien avec les missions réalisées en entreprise.",
      ],
      website_link:
        "https://www.groupe-alternance.com/regions/auvergne-rhone-alpes/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "JIRA",
      subtitle: "Logiciel de gestion de projet",
      logo_path: "jira.png",
      certificate_link: "https://www.atlassian.com/fr/software/jira",
      alt_name: "JIRA",
      color_code: "#ffffff",
    },
    {
      title: "SAP",
      subtitle: "ERP pour la gestion des entreprises",
      logo_path: "sap.png",
      certificate_link: "https://www.sap.com/france/index.html",
      alt_name: "SAP",
      color_code: "#ffffff",
    },
    {
      title: "Excel",
      subtitle: "Tableur pour l'analyse de données",
      logo_path: "excel.png",
      certificate_link: "https://www.microsoft.com/fr-fr/microsoft-365/excel",
      alt_name: "Microsoft Excel",
      color_code: "#ffffff",
    },
    {
      title: "Miro",
      subtitle: "Outil de collaboration visuelle",
      logo_path: "miro.png",
      certificate_link: "https://www.microsoft.com/fr-fr/microsoft-365/excel",
      alt_name: "Miro",
      color_code: "#ffffff",
    },
    {
      title: "PowerPoint",
      subtitle: "Création de présentations",
      logo_path: "powerpoint.png",
      certificate_link:
        "https://www.microsoft.com/fr-fr/microsoft-365/powerpoint",
      alt_name: "Microsoft PowerPoint",
      color_code: "#ffffff",
    },
    {
      title: "Teams",
      subtitle: "Collaboration et communication",
      logo_path: "teams.png",
      certificate_link: "https://teams.microsoft.com/v2/",
      alt_name: "Microsoft Teams",
      color_code: "#ffffff",
    },
    {
      title: "PowerBI",
      subtitle: "Analyse et visualisation de données",
      logo_path: "powerbi.png",
      certificate_link:
        "https://www.microsoft.com/fr-fr/power-platform/products/power-bi",
      alt_name: "Microsoft PowerBI",
      color_code: "#ffffff",
    },
    {
      title: "SQL",
      subtitle: "Langage de requête pour bases de données",
      logo_path: "sql.png",
      certificate_link: "https://sql.sh/",
      alt_name: "SQL",
      color_code: "#ffffff",
    },
    {
      title: "Canva",
      subtitle: "Design graphique et création visuelle",
      logo_path: "canva.png",
      certificate_link: "https://www.canva.com/",
      alt_name: "Canva",
      color_code: "#ffffff",
    },
    {
      title: "Word",
      subtitle: "Traitement de texte",
      logo_path: "word.png",
      certificate_link: "https://www.microsoft.com/fr-fr/microsoft-365/word",
      alt_name: "Microsoft Word",
      color_code: "#ffffff",
    },
    {
      title: "SharePoint",
      subtitle: "Gestion de contenu et collaboration",
      logo_path: "sharepoint.png",
      certificate_link:
        "https://www.microsoft.com/fr-fr/microsoft-365/sharepoint/collaboration",
      alt_name: "Microsoft SharePoint",
      color_code: "#ffffff",
    },
    {
      title: "Outlook",
      subtitle: "Messagerie électronique",
      logo_path: "outlook.png",
      certificate_link:
        "https://www.microsoft.com/fr-fr/microsoft-365/outlook/email-and-calendar-software-microsoft-outlook",
      alt_name: "Microsoft Outlook",
      color_code: "#ffffff",
    },
  ],
};

// Experience Page
const experience = {
  title: "Expériences",
  subtitle: "Mes différentes expériences professionnelles",
  description:
    "Avec plus de 7 ans d'expérience en management et développement commercial dans le secteur de l'énergie, j'ai supervisé des équipes pluridisciplinaires et piloté des projets transversaux, apportant une vision stratégique et opérationnelle. J'ai développé une expertise en gestion de projets, optimisation des performances et relation client, en assurant la satisfaction des parties prenantes. Mon parcours témoigne de ma capacité à m'adapter, innover et relever de nouveaux défis avec passion et détermination.",
  header_image_path: "undraw_career_development_re_sv91.svg",
  sections: [
    {
      title: "Mes expériences",
      work: true,
      experiences: [
        {
          title: "Co Manager",
          company: "Enedis",
          company_url: "https://www.enedis.fr/",
          logo_path: "enedis3.png",
          duration: "Octobre 2023 - Octobre 2024",
          location: "Lyon, France",
          description:
            "Au sein d’Enedis, j’évoluais dans un environnement en pleine mutation, marqué par des enjeux de sécurité et une forte exigence de performance opérationnelle. En tant que Co Manager, mon rôle était celui de facilitatrice et de soutien stratégique pour les managers. J'étais chargée de favoriser une collaboration fluide entre les équipes et de renforcer la culture de sécurité, essentielle dans ce secteur. Dans cette position, je faisais le lien entre les orientations de l’entreprise et les équipes sur le terrain, en apportant un cadre structuré pour accompagner la transformation digitale et organisationnelle d’Enedis.",
          color: "#1323db",
        },
        {
          title: "Assistante Manager BtoB",
          company: "TotalEnergies Électricité et Gaz France",
          company_url: "https://totalenergies.com/",
          logo_path: "total.png",
          duration: "Février 2022 - Septembre 2023",
          location: "Lyon, France",
          description:
            "Chez TotalEnergies, dans un secteur énergétique en pleine transition et hautement concurrentiel, mon rôle d'Assistante Manager BtoB était axé sur le soutien aux équipes commerciales et sur l’optimisation des processus de collaboration. J'évoluais dans un contexte où l'adaptation rapide aux évolutions du marché était cruciale. Ma position me permettait d’être un relais stratégique entre les différents départements, facilitant l’alignement entre les besoins des clients et les objectifs internes. C’était un rôle qui exigeait une vision transversale et une capacité d’adaptation aux défis d’un marché en constante évolution.",
          color: "#1323db",
        },
        {
          title: "Key Account Manager BtoB",
          company: "IXIT Beeper",
          company_url: "https://www.ixit.fr/",
          logo_path: "beeper.png",
          duration: "Septembre 2021 - Janvier 2022",
          location: "Lyon, France",
          description:
            "Chez IXIT Beeper, dans un environnement BtoB centré sur des ventes complexes et stratégiques, j'occupais un rôle clé en tant que Key Account Manager. Mon travail s'inscrivait dans un contexte exigeant où la gestion d’un portefeuille de grands comptes impliquait une maîtrise fine des négociations commerciales et une capacité à construire des relations de confiance sur le long terme. Dans cet univers concurrentiel, j'étais responsable de l’animation des ventes en ligne et du développement d’une offre adaptée aux besoins spécifiques des clients stratégiques. Mon rôle requérait une vision globale du marché et un sens aigu de la négociation pour maximiser les résultats tout en assurant une expérience client de qualité.",
          color: "#1323db",
        },
        {
          title: "Assistante Manager & Marketing BtoB / BtoC",
          company: "Carrefour",
          company_url: "https://www.carrefour.fr/",
          logo_path: "carrefour.png",
          duration: "Août 2019 - Août 2021",
          location: "Lyon, France",
          description:
            "Chez Carrefour, un acteur majeur de la grande distribution, j'évoluais dans un environnement extrêmement réactif et orienté client, où chaque interaction avait un impact direct sur la satisfaction et la fidélité de la clientèle. Mon rôle d’Assistante Manager et Marketing m’a permis de jouer un rôle pivot entre les équipes et les stratégies marketing de l’entreprise. J'étais au cœur de la relation client et de la gestion des opérations en magasin, assurant l’alignement entre les attentes des clients et les priorités de l’entreprise dans un contexte de grande distribution exigeant rapidité et efficacité.",
          color: "#1323db",
        },
        {
          title: "Chargée de développement Commercial et Marketing",
          company: "Carrefour",
          company_url: "https://www.carrefour.fr/",
          logo_path: "carrefour.png",
          duration: "Août 2017 - Août 2019",
          location: "Lyon, France",
          description:
            "J’ai eu l’opportunité de rejoindre Carrefour, un acteur clé de la grande distribution. Cette expérience m’a permis de découvrir le fonctionnement d’un environnement réactif et centré sur le client. En tant qu’Assistante Manager et Marketing, j’assurais le lien entre les stratégies de l’entreprise et les opérations en magasin, avec un accent particulier sur la satisfaction client et l’optimisation des processus internes. J’ai également pu développer des compétences essentielles en gestion d’équipe et en communication, tout en contribuant à la fidélisation de la clientèle dans un contexte dynamique.",
          color: "#1323db",
        },
      ],
    },
  ],
};

// Competences Page
const projectsHeader = {
  title: "Compétences",
  description:
    "Mes projets intègrent une large gamme d'outils technologiques de pointe pour optimiser les performances et soutenir la croissance commerciale. Mon expertise se concentre sur la conception de solutions stratégiques qui maximisent l'efficacité et l'impact au sein des équipes et des processus.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "neuro-symbolic-sudoku-solver",
      name: "Neuro-Symbolic Sudoku Solver",
      createdAt: "2023-07-02T00:00:00Z",
      description: "Paper published in KDD KiML 2023",
      url: "https://arxiv.org/abs/2307.00653",
    },
    {
      id: "mdp-diffusion",
      name: "MDP-Diffusion",
      createdAt: "2023-09-19T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/mdp-diffusion/",
    },
    {
      id: "consistency-models",
      name: "Consistency Models",
      createdAt: "2023-10-12T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/consistency-models/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Me contacter",
    profile_image_path: "ines_animated.png",
    description:
      "Vous souhaitez en savoir plus sur mon parcours ou discuter d'une potentielle collaboration ? \n" +
      "\n" +
      "Je suis disponible pour échanger sur toute opportunité ou projet en lien avec mes compétences. N'hésitez pas à me contacter via les moyens suivants\n",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I like to document some of my experiences in professional career journey as well as some technical knowledge sharing.",
    link: "https://blogs.ashutoshhathidara.com/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Localisation",
    subtitle: "Lyon - Auvergne-Rhône-Alpes, France",
    locality: "",
    country: "France",
    region: "Rhônes-Alpes",
    postalCode: "69000",
    streetAddress: "Saratoga Avenue",
    avatar_image_path: "contact.svg",
    location_map_link: "https://maps.app.goo.gl/NvYZqa34Wye4tpS17",
  },
  phoneSection: {
    title: "Téléphone",
    subtitle: "+33 609679315",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
